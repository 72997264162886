export function Resources() {
    return <div id="resources" className="py-5 bg-offgold">
        <div className="container-md py-5 text-center">
            <h2 className="text-gold text-uppercase">Resources</h2>
            <div className="pb-5"></div>
            <button
                onClick={() => {
                    window.open("/KRAFT-CHALLENGE-2025.pdf", "blank")
                }}
                className="btn bg-gold rounded-pill px-5 py-3 fw-bold text-white">Download templates and guidelines here</button>
        </div>
    </div>
}