import EmblaCarousel from "../Components/EmblaCarousel"

const OPTIONS = { align: 'start' }
const SLIDE_COUNT = 6
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

export function JudgesAndMentors() {
    const judgesAndMentors = [
        {
            name: "Dr. Benjamin Cho",
            title: "Professor of Cybersecurity",
            organization: "Tech University",
            image: "judges 1"
        },
        {
            name: "Emma Cartwright",
            title: "Senior Vice President of Digital Strategy",
            organization: "FutureVision",
            image: "judges 2"
        },
        {
            name: "James Thompson",
            title: "Founder & CEO",
            organization: "Visionary Enterprises",
            image: "judges 3"
        },
        {
            name: "Dr. Benjamin Cho",
            title: "Professor of Cybersecurity",
            organization: "Tech University",
            image: "judges 1"
        },
        {
            name: "Emma Cartwright",
            title: "Senior Vice President of Digital Strategy",
            organization: "FutureVision",
            image: "judges 2"
        },
        {
            name: "James Thompson",
            title: "Founder & CEO",
            organization: "Visionary Enterprises",
            image: "judges 3"
        },
    ]
    return <div id="judges-and-mentors" className="py-5 bg-gold">
        <div className="container-md py-5 text-center">
            <h2 className="text-white text-uppercase">Judges & Mentors</h2>
            <div className="pb-5"></div>
            <EmblaCarousel slides={judgesAndMentors} options={OPTIONS} />
            {/* <div className="row">
                {judgesAndMentors.map((judgeAndMentor, index) => {
                    return <div className={`col-12 col-md-4${index ? " d-none d-md-block" : ""}`}>
                    <div className="bg-light mb-5" style={{
                        height: "25rem",
                        borderRadius: "5rem 5rem 0 0"
                    }}></div>
                    <h3 className="text-white">{judgeAndMentor.name}</h3>
                    <p className="text-white">
                        {judgeAndMentor.title},
                        <div>{judgeAndMentor.organization}</div>
                    </p>
                    </div>
                })}
            </div> */}
            <div className="pb-5"></div>
            <p className="text-white">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
        </div>
    </div>
}