export function Index() {
    return <div className="py-5 bg-gold">
        <div className="container-md py-5">
            <div className="row">
                <a href="#overview" className="col-md col-6 text-center py-3 py-md-0"><img src="./../img/innovation-campaign/icons/pg2-btnoverview.png" alt="" style={{width: "3.75rem"}} className="pb-3"/><p className="text-white">Overview</p></a>
                <a href="#how-to-participate" className="col-md col-6 text-center py-3 py-md-0"><img src="./../img/innovation-campaign/icons/pg2-btnhowtojoin.png" alt="" style={{width: "3.75rem"}} className="pb-3"/><p className="text-white">How to Participate</p></a>
                <a href="#topics-and-themes" className="col-md col-6 text-center py-3 py-md-0"><img src="./../img/innovation-campaign/icons/pg2-btncategories.png" alt="" style={{width: "3.75rem"}} className="pb-3"/><p className="text-white">Topics and Themes</p></a>
                <a href="#resources" className="col-md col-6 text-center py-3 py-md-0"><img src="./../img/innovation-campaign/icons/pg2-btnresouces.png" alt="" style={{width: "3.75rem"}} className="pb-3"/><p className="text-white">Resources</p></a>
                {/* <a href="#judges-and-mentors" className="col-md-2 col-6 text-center"><img src="./../img/innovation-campaign/icons/pg2-judges&mentor.png" alt="" style={{width: "3.75rem"}} className="pb-3"/><p className="text-white">Judges & Mentors</p></a> */}
                <a href="#contact-us" className="col-md col-12 text-center py-3 py-md-0"><img src="./../img/innovation-campaign/icons/pg2-btncontactus.png" alt="" style={{width: "3.75rem"}} className="pb-3"/><p className="text-white">Contact Us</p></a>
            </div>
        </div>
    </div>
}