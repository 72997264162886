export function HowToJoin() {
    return <div id="how-to-participate" className="py-5 position-relative">
        <img src="./../img/innovation-campaign/pg4-bg.png" className="position-absolute bottom-0 d-none d-md-block" alt="" style={{zIndex: 1}} />
        <img src="./../img/innovation-campaign/pg4-bg.png" className="position-absolute top-0 d-md-none" alt="" style={{
            zIndex: 1,
            scale: "5",
        }} />
        <div className="container-md py-5 d-flex flex-column align-items-center">
            <div className="row">
                <div className="col-12 col-md-7 pe-md-5 order-1 order-md-0">
                    <h2 className="text-gold text-uppercase">How to Participate</h2>
                    <div className="pb-3"></div>
                    <h3>How to prepare your submission</h3>
                    <p>Participants to submit project proposals and undergo evaluation. Shortlisted projects will proceed to the pitching session.</p>
                    <div className="pb-3"></div>
                    <h3>Submission guidelines</h3>
                    <p className="px-3 px-md-0">
                        <ul>
                            <li>
                                Open to the public working and residing in Malaysia, including Team KPJ consultants and KPJ Healthcare students
                                <ul className="ps-3" style={{listStyleType: "circle"}}>
                                    <li>KPJ Healthcare consultants and KPJ University Students</li>
                                    <li>University Students studying at local institutions</li>
                                    <li>Academics from Institutions of Higher Education</li>
                                    <li>Individual Innovators</li>
                                    <li>Malaysian-registered SME’s</li>
                                </ul>
                            </li>
                            <li>Each team may submit one proposal per category.</li>
                            <li>
                                Proposals must include:
                                <ul className="ps-3" style={{listStyleType: "circle"}}>
                                    <li>Project Title</li>
                                    <li>Objective</li>
                                    <li>Methodology</li>
                                    <li>Expected Outcomes</li>
                                    <li>Alignment with KPJ's C.A.R.E. values</li>
                                </ul>
                            </li>
                            <li>All submissions must be in English and adhere to the prescribed format and templates.</li>
                            <li>Projects must be submitted via the official microsite before the deadline.</li>
                            <li>Resubmission of proposals is only allowed if significant enhancements or additional information have been incorporated.</li>
                        </ul>
                    </p>
                </div>
                <div className="col-12 col-md-5 pb-5 pb-md-0">
                    <img src="./../img/innovation-campaign/pg4-image.png" alt="" />
                </div>
            </div>
            <button
                onClick={() => {
                    window.open("https://forms.gle/HfJt65wtV2AN4ZPt6", "blank")
                }}
                className="btn bg-gold py-3 px-5 rounded-pill text-white fw-bold mt-5" style={{zIndex: 1}}>Submit Idea</button>
        </div>
    </div>
}