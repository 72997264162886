export function Main() {
    return <>
    <div className="py-5 d-flex align-items-center d-none d-md-block" style={{
        backgroundImage: "url('./../img/innovation-campaign/pg1-bg.png')",
        height: "100vh"
    }}>
        <div className="container-md text-center py-5 d-flex flex-column justify-content-center align-items-center h-100">
            <img src="./../img/innovation-campaign/logos/pg1-logo.png" alt="" style={{width: "12.5rem"}} />
            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                {/* <h1 className="text-white text-uppercase montserrat">K.R.A.F.T Challenge 2025</h1> */}
                <img src="./../img/innovation-campaign/logos/kraft_logo.png" alt="" className="pb-5" style={{width: "25rem"}} />
                <h3 className="text-white">KPJ Redefining Aspirations for Tomorrow</h3>
                <p className="text-white">
                    Ready to revolutionise healthcare with your ideas? This is your golden opportunity to drive meaningful change and be rewarded for it. The <span className="fw-bold">K.R.A.F.T. CHALLENGE 2025</span> is a premier platform for visionaries, innovators and changemakers to push the boundaries of healthcare excellence. If you have an idea that could redefine the future of healthcare, now is the time to bring it to life.
                </p>
            </div>
            <div>
                <button
                onClick={() => {
                    window.open("https://forms.gle/HfJt65wtV2AN4ZPt6", "blank")
                }}
                className="btn rounded-pill bg-white py-3 px-5 w-0 fw-bold">
                    Sign Up Now
                </button>
            </div>
        </div>
    </div>
    <div className="py-5 d-flex align-items-center d-md-none" style={{
        backgroundImage: "url('./../img/innovation-campaign/pg1-bg-mobile.png')",
        height: "100vh",
        backgroundSize: "cover"
    }}>
        <div className="container-md text-center py-5 d-flex flex-column justify-content-center align-items-center h-100">
            <img src="./../img/innovation-campaign/logos/pg1-logo.png" alt="" style={{width: "12.5rem"}} />
            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                {/* <h1 className="text-white text-uppercase montserrat">K.R.A.F.T Challenge 2025</h1> */}
                <img src="./../img/innovation-campaign/logos/kraft_logo.png" alt="" className="pb-3" style={{width: "18.325rem"}} />
                <h3 className="text-white">KPJ Redefining Aspirations for Tomorrow</h3>
                <p className="text-white">
                    Ready to revolutionise healthcare with your ideas? This is your golden opportunity to drive meaningful change and be rewarded for it. The <span className="fw-bold">K.R.A.F.T. CHALLENGE 2025</span> is a premier platform for visionaries, innovators and changemakers to push the boundaries of healthcare excellence. If you have an idea that could redefine the future of healthcare, now is the time to bring it to life.
                </p>
            </div>
            <div>
                <button
                onClick={() => {
                    window.open("https://forms.gle/HfJt65wtV2AN4ZPt6", "blank")
                }}
                className="btn rounded-pill bg-white py-3 px-5 w-0 fw-bold">
                    Sign Up Now
                </button>
            </div>
        </div>
    </div>
    </>
}