export function Overview() {
    return <div id="overview" className="py-5 bg-offgold" style={{position: "relative"}}>
        <div className="container-md py-5">
            <div className="container-fluid" style={{
                position: "absolute",
                top: 0,
                left: 0
            }} >
                <div className="container-md d-flex justify-content-end">
                    <div className="d-flex bg-gold" style={{
                        width: "fit-content",
                    }}>
                        <div className="p-3 bg-offgold" style={{
                            // backgroundColor: "white",
                            borderRadius: "0 3rem 0 0",
                        }}></div>
                        <div className="p-3 bg-offgold" style={{
                            // backgroundColor: "white",
                            borderRadius: "3rem 0 0 0",
                        }}></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-5">
                    <img src="./../img/innovation-campaign/pg3-image.png" alt="" />
                </div>
                <div className="col-12 col-md-7 ps-md-5">
                    <h2 className="text-gold text-uppercase">Overview</h2>
                    <p>We are calling on bright minds from across industries to propose groundbreaking ideas, impactful projects and transformative process improvements that reflect our core values of being Committed, Accountable, Respectful and Excellent (C.A.R.E.). This is your chance to push the boundaries of innovation with the possibility of developing your idea towards pre-commercialisation or full commercialisation. Bring your visionary concepts to life and shape the future with us.</p>
                    <div className="pb-3"></div>
                    <h3>Why Join the Challenge?</h3>
                    <p>By participating in the K.R.A.F.T. CHALLENGE 2025, you will:</p>
                    <p>
                        <table>
                            <tr className="align-top">
                                <th>Inspire innovation</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>Contribute ideas that drive meaningful change in healthcare.</td>
                            </tr>
                            <tr className="align-top">
                                <th>Achieve measurable impacts</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>Drive real-world advancements in patient care and hospital operations through winning ideas implemented within KPJ Healthcare.</td>
                            </tr>
                            <tr className="align-top">
                                <th>Foster teamwork</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>Strengthen collaboration and ownership through impactful projects.</td>
                            </tr>
                            <tr className="align-top">
                                <th>Gain support</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>KPJ Research and Innovation Centre (KPJRIC) will support selected submissions in the pre-commercialisation and/or commercialisation stages.</td>
                            </tr>
                            <tr className="align-top">
                                <th>Be recognised and rewarded</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>Stand a chance to win a share of the RM104,000 prize pool and gain industry recognition.</td>
                            </tr>
                        </table>
                    </p>
                    <div className="pb-3"></div>
                    <h3>Key Themes and Focus Areas</h3>
                    <p>The K.R.A.F.T. CHALLENGE 2025 focuses on driving innovation across key areas, including clinical excellence, patient care, operational efficiency, community impact, sustainability in healthcare and advancements in technology, digitalization, biotechnology and pharmaceuticals.</p>
                    <div className="pb-3"></div>
                    <h3>Competition Timeline</h3>
                    <p>
                        <table>
                            <tr className="align-top">
                                <th>1 March - 30 Sept 2025</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>Submission of project proposals</td>
                            </tr>
                            <tr className="align-top">
                                <th>1 - 30 Oct 2025</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>Pitching sessions for shortlisted participants</td>
                            </tr>
                            <tr className="align-top">
                                <th>1 Nov 2025</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>Announcement of finalists</td>
                            </tr>
                            <tr className="align-top">
                                <th>25 Nov 2025</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>Grand Finale at MIH Megatrends 2025</td>
                            </tr>
                        </table>
                    </p>
                    <div className="pb-3"></div>
                    <h3>Prizes & Recognition</h3>
                    <p>
                        Selected submissions will gain support in developing their ideas towards pre-commercialisation and/or commercialisation.
                    </p>
                    <p>
                        <table>
                            <tr className="align-top">
                                <th>Champion</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>RM35,000 and support towards commercialisation</td>
                            </tr>
                            <tr className="align-top">
                                <th>1st Runner-Up</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>RM20,000</td>
                            </tr>
                            <tr className="align-top">
                                <th>2nd Runner-Up</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>RM10,000</td>
                            </tr>
                            <tr className="align-top">
                                <th>Best in Research Award</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>RM5,000</td>
                            </tr>
                            <tr className="align-top">
                                <th>5 Consolation Prizes</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>RM5,000 each</td>
                            </tr>
                            <tr className="align-top">
                                <th>3 Judges’ Special Awards</th>
                                <td>&nbsp;:&nbsp;</td>
                                <td>RM3,000 each</td>
                            </tr>
                        </table>
                    </p>
                </div>
            </div>
        </div>
    </div>
}