import { Main } from "./Sections/Main";
import { Index } from "./Sections/Index";
import { Overview } from "./Sections/Overview";
import { HowToJoin } from "./Sections/HowToJoin";
import { Resources } from "./Sections/Resources";
import { JudgesAndMentors } from "./Sections/JudgesAndMentors";
import { ContactUs } from "./Sections/ContactUs";
import { Categories } from "./Sections/Categories";

export function InnovationCampaign() {
    return <>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet" />
    <style>
        {`
        * {
            font-family: Aptos !important;
        }
        header, .kpj-footer, .navigation-bottom {
            display: none !important;
        }
        li {
            list-style: inherit !important;
        }

        .bg-gold {
            background-color: rgb(150, 127, 101) !important;
        }
        .text-gold {
            color: rgb(150, 127, 101) !important;
        }
        h1 {
            font-family: 'Montserrat' !important;
        }
        .bg-offgold {
            background-color: #F3EEEC !important;
        }
        th {
            white-space: nowrap;
        }
        `}
    </style>
    <div style={{overflowX: "hidden"}}>
        <Main />
        <Index />
        <Overview />
        <HowToJoin />
        <Categories />
        <Resources />
        {/* <JudgesAndMentors /> */}
        <ContactUs />
    </div>
</>
}